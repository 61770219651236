import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading: false,
  privacyPolicies: [],
  error: null,
};

const initGetPrivacyPolicies = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successGetPrivacyPolicies = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    privacyPolicies: action.privacyPolicies,
  };
};

const failGetPrivacyPolicies = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const privacyPolicyReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_PRIVACY_POLICIES:
      return initGetPrivacyPolicies(state, action);
    case actionTypes.SUCCESS_GET_PRIVACY_POLICIES:
      return successGetPrivacyPolicies(state, action);
    case actionTypes.FAIL_GET_PRIVACY_POLICIES:
      return failGetPrivacyPolicies(state, action);
    default:
      return { ...state };
  }
};

export default privacyPolicyReducer;
