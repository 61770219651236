import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading_wishlst: false,
  wishlist_data: [],
  error_wishlist: null,
};

const initGeWishlist = (state, action) => {
  return {
    ...state,
    loading_wishlst: action.loading_wishlst,
  };
};

const successGeWishlist = (state, action) => {
  return {
    ...state,
    loading_wishlst: action.loading_wishlst,
    wishlist_data: action.wishlist_data,
    error_wishlist: action.error_wishlist,
  };
};

const failGeWishlist = (state, action) => {
  return {
    ...state,
    loading_wishlst: action.loading_wishlst,
    wishlist_data: action.wishlist_data,
    error_wishlist: action.error_wishlist,
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_WISHLIST:
      return initGeWishlist(state, action);
    case actionTypes.SUCCESS_GET_WISHLIST:
      return successGeWishlist(state, action);
    case actionTypes.FAIL_GET_WISHLIST:
      return failGeWishlist(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
