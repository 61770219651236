import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading: false,
  faqs: [],
  error: null,
};

const initGetFaqs = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successGetFaqs = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    faqs: action.faqs,
  };
};

const failGetFaqs = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const faqReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_FAQS:
      return initGetFaqs(state, action);
    case actionTypes.SUCCESS_GET_FAQS:
      return successGetFaqs(state, action);
    case actionTypes.FAIL_GET_FAQS:
      return failGetFaqs(state, action);
    default:
      return { ...state };
  }
};

export default faqReducer;
