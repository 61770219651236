import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading_preloved_products: false,
  preloved_products: [],
  error_preloved_products: null,
  success_add_prelove: null,
  error_add_prelove: null,
  //profile products:
  loading_profile_preloved_products: false,
  profile_preloved_products: [],
  error_profile_preloved_products: null,
  //preloved sales:
  preloved_sales: [],
  preloved_sales_err: null,
  //preloved buys
  preloved_buys: [],
  preloved_buys_err: null,
  loadingPrelovedCheckout: false,
  prelovedCheckoutData: null,
  errorPrelovedCheckout: null,
};

const initGetPreloved = (state, action) => {
  return {
    ...state,
    loading_preloved_products: action.loading_preloved_products,
  };
};

const successGetPreloved = (state, action) => {
  return {
    ...state,
    loading_preloved_products: action.loading_preloved_products,
    preloved_products: action.preloved_products,
  };
};

const failGetPreloved = (state, action) => {
  return {
    ...state,
    loading_preloved_products: action.loading_preloved_products,
    error_preloved_products: action.error_preloved_products,
  };
};
//############################
//############################
//Add prloved
const successAddPreloved = (state, action) => {
  return {
    ...state,
    success_add_prelove: action.success_add_prelove,
    error_add_prelove: action.error_add_prelove,
  };
};
const failAddPreloved = (state, action) => {
  return {
    ...state,
    success_add_prelove: action.success_add_prelove,
    error_add_prelove: action.error_add_prelove,
  };
};
//############################
//############################
//prloved products:
const initGetPrelovedProfileProducts = (state, action) => {
  return {
    ...state,
    loading_profile_preloved_products: action.loading_profile_preloved_products,
  };
};

const successGetPrelovedProfileProducts = (state, action) => {
  //console.log(action.profile_preloved_products);
  return {
    ...state,
    loading_profile_preloved_products: action.loading_profile_preloved_products,
    profile_preloved_products: action.profile_preloved_products,
  };
};

const failGetPrelovedProfileProducts = (state, action) => {
  return {
    ...state,
    loading_profile_preloved_products: action.loading_profile_preloved_products,
    error_profile_preloved_products: action.error_profile_preloved_products,
  };
};

const successPrelovedSales = (state, action) => {
  return {
    ...state,
    preloved_sales: action.preloved_sales,
  };
};
const failPrelovedSales = (state, action) => {
  return {
    ...state,
    preloved_sales_err: action.preloved_sales_err,
  };
};
const successPrelovedBuys = (state, action) => {
  return {
    ...state,
    preloved_buys: action.preloved_buys,
  };
};
const failPrelovedBuys = (state, action) => {
  return {
    ...state,
    preloved_buys_err: action.preloved_buys_err,
  };
};

const initPrelovedCheckout = (state, action) => {
  return {
    ...state,
    loadingPrelovedCheckout: action.loadingPrelovedCheckout,
  };
};

const successPrelovedCheckout = (state, action) => {
  //console.log(action.profile_preloved_products);
  return {
    ...state,
    loadingPrelovedCheckout: action.loadingPrelovedCheckout,
    prelovedCheckoutData: action.prelovedCheckoutData,
  };
};

const failPrelovedCheckout = (state, action) => {
  return {
    ...state,
    loadingPrelovedCheckout: action.loadingPrelovedCheckout,
    errorPrelovedCheckout: action.errorPrelovedCheckout,
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_PRELOVED:
      return initGetPreloved(state, action);
    case actionTypes.SUCCESS_GET_PRELOVED:
      return successGetPreloved(state, action);
    case actionTypes.FAIL_GET_PRELOVED:
      return failGetPreloved(state, action);
    case actionTypes.SUCCESS_ADD_PRELOVED:
      return successAddPreloved(state, action);
    case actionTypes.FAIL_ADD_PRELOVED:
      return failAddPreloved(state, action);
    //profile product:
    case actionTypes.INIT_GET_PROFILE_PRELOVED:
      return initGetPrelovedProfileProducts(state, action);
    case actionTypes.SUCCESS_GET_PROFILE_PRELOVED:
      return successGetPrelovedProfileProducts(state, action);
    case actionTypes.FAIL_GET_PROFILE_PRELOVED:
      return failGetPrelovedProfileProducts(state, action);
    //preloved sales:
    case actionTypes.SUCCESS_PRELOVED_SALES:
      return successPrelovedSales(state, action);
    case actionTypes.FAIL_PRELOVED_SALES:
      return failPrelovedSales(state, action);
    //preloved buys
    case actionTypes.SUCCESS_PRELOVED_BUYS:
      return successPrelovedBuys(state, action);
    case actionTypes.FAIL_PRELOVED_BUYS:
      return failPrelovedBuys(state, action);
    case actionTypes.INIT_PRELOVED_CHECKOUT:
      return initPrelovedCheckout(state, action);
    case actionTypes.SUCCESS_PRELOVED_CHECKOUT:
      return successPrelovedCheckout(state, action);
    case actionTypes.FAIL_PRELOVED_CHECKOUT:
      return failPrelovedCheckout(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
