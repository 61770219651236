import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading_shop: false,
  loadingShopProducts: false,
  errorLoadingShopProducts: false,
  shop_data: {},
  shop_products: [],
  error_shop: null,
};

const initGetShop = (state, action) => {
  return {
    ...state,
    loading_shop: action.loading_shop,
  };
};

const successGetShop = (state, action) => {
  return {
    ...state,
    loading_shop: action.loading_shop,
    shop_data: action.shop_data,
  };
};

const failGetShop = (state, action) => {
  return {
    ...state,
    loading_shop: action.loading_shop,
    error_shop: action.error_shop,
  };
};

const initGetShopProducts = (state, action) => {
  return {
    ...state,
    loadingShopProducts: action.loadingShopProducts,
  };
};

const successGetShopProducts = (state, action) => {
  return {
    ...state,
    shop_products: action.shop_products,
    loadingShopProducts: action.loadingShopProducts,
  };
};

const failGetShopProducts = (state, action) => {
  return {
    ...state,
    loadingShopProducts: action.loadingShopProducts,
    errorLoadingShopProducts: action.errorLoadingShopProducts,
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_SHOP:
      return initGetShop(state, action);
    case actionTypes.SUCCESS_GET_SHOP:
      return successGetShop(state, action);
    case actionTypes.FAIL_GET_SHOP:
      return failGetShop(state, action);
    case actionTypes.INIT_GET_SHOP_PRODUCTS:
      return initGetShopProducts(state, action);
    case actionTypes.SUCCESS_GET_SHOP_PRODUCTS:
      return successGetShopProducts(state, action);
    case actionTypes.FAIL_GET_SHOP_PRODUCTS:
      return failGetShopProducts(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
