import { Fragment } from "react";
import Head from "next/head";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import "react-chat-widget/lib/styles.css";
import "../assets/scss/style.scss";
import "react-quill/dist/quill.snow.css";
import { useRouter } from "next/router";
import { useStore } from "../redux/store";
import Script from "next/script";
import { appWithTranslation } from "next-i18next";
import SSRProviderProps from "react-bootstrap/SSRProvider";

function App({ Component, pageProps }) {
  const store = useStore(pageProps.initialReduxState);
  const router = useRouter();
  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API
  };

  return (
    <Fragment>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script strategy="lazyOnload" id="ga">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        `}
      </Script>
      <Script strategy="lazyOnload" id="hotjar">
      {`
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_SITEID},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `}
      </Script>
      <Script strategy="lazyOnload" id="freshdesk1">
        {`window.fwSettings={
        'widget_id':73000000284
        };
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`}{" "}
      </Script>
      <Script strategy="lazyOnload" src="https://widget.freshworks.com/widgets/73000000284.js" />
      <Head>
        <title>Pcari Shopping | Sell your products with us.</title>
      </Head>
      <ToastProvider placement="bottom-center">
        <Provider store={store}>
          {/* {router.pathname == "/" ? (
            <></>
          ) : router.pathname.includes("/shop/product-basic/") ||
            router.pathname.includes("/seller/shop-products") ? (
            <ChatWidget
              handleNewUserMessage={handleNewUserMessage}
              title="Seller Name"
              subtitle="Ask your questions here."
            ></ChatWidget>
          ) : (
            <></>
          )} */}
          <SSRProviderProps>
            <Component {...pageProps} />
          </SSRProviderProps>
        </Provider>
      </ToastProvider>
    </Fragment>
  );
}

export default appWithTranslation(App);
