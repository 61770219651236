import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  loading_details: false,
  profile_details: null,
  error_details: null,
  //address
  loading_address: false,
  address_shipping: [],
  address_billing: null,
  error_address: null,
  //orders:
  orders_loading: false,
  orders_data: [],
  orders_error: null,
  orderDetailLoading: false,
  orderDetail: null,
  orderDetailError: null,
};

const initGetDetails = (state, action) => {
  return {
    ...state,
    loading_details: action.loading_details,
  };
};

const successGetDetails = (state, action) => {
  return {
    ...state,
    loading_details: action.loading_details,
    profile_details: action.profile_details,
  };
};

const failGetDetails = (state, action) => {
  return {
    ...state,
    loading_details: action.loading_details,
    error_details: action.error_details,
  };
};

//addrees
const initGetAddress = (state, action) => {
  return {
    ...state,
    loading_address: action.loading_address,
  };
};

const successGetAddress = (state, action) => {
  return {
    ...state,
    loading_address: action.loading_address,
    address_shipping: action.address_shipping,
    address_billing: action.address_billing,
  };
};

const failGetAddress = (state, action) => {
  return {
    ...state,
    loading_address: action.loading_address,
    error_address: action.error_address,
  };
};

//orders :
const initGetOrder = (state, action) => {
  return {
    ...state,
    orders_loading: action.orders_loading,
    orders_data: action.orders_data,
    orders_error: action.orders_error,
  };
};

const successGetOrder = (state, action) => {
  return {
    ...state,
    orders_loading: action.orders_loading,
    orders_data: action.orders_data,
    orders_error: action.orders_error,
  };
};

const failGetOrder = (state, action) => {
  return {
    ...state,
    orders_loading: action.orders_loading,
    orders_error: action.orders_error,
  };
};

const initGetOrderDetail = (state, action) => {
  return {
    ...state,
    orderDetailLoading: action.orderDetailLoading,
  };
};

const successGetOrderDetail = (state, action) => {
  return {
    ...state,
    orderDetailLoading: action.orderDetailLoading,
    orderDetail: action.orderDetail,
    orderDetailError: action.orderDetailError
  };
};

const failGetOrderDetail = (state, action) => {
  return {
    ...state,
    orderDetailLoading: action.orderDetailLoading,
    orderDetailError: action.orderDetailError,
  };
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_DETAILS:
      return initGetDetails(state, action);
    case actionTypes.SUCCESS_GET_DETAILS:
      return successGetDetails(state, action);
    case actionTypes.FAIL_GET_DETAILS:
      return failGetDetails(state, action);
    //address
    case actionTypes.INIT_GET_ADDRESS:
      return initGetAddress(state, action);
    case actionTypes.SUCCESS_GET_ADDRESS:
      return successGetAddress(state, action);
    case actionTypes.FAIL_GET_ADDRESS:
      return failGetAddress(state, action);
    //orders
    case actionTypes.INIT_GET_ORDERS:
      return initGetOrder(state, action);
    case actionTypes.SUCCESS_GET_ORDERS:
      return successGetOrder(state, action);
    case actionTypes.FAIL_GET_ORDERS:
      return failGetOrder(state, action);
    case actionTypes.INIT_GET_ORDER_DETAIL:
      return initGetOrderDetail(state, action);
    case actionTypes.SUCCESS_GET_ORDER_DETAIL:
      return successGetOrderDetail(state, action);
    case actionTypes.FAIL_GET_ORDER_DETAIL:
      return failGetOrderDetail(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
