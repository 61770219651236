import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading: false,
  user: null,
  error: null,
  currentStep: 1,
  completedOnboarding: true,
  sendingVerificationCode: false,
  verificationCode: null,
  errorSendVerification: null,
  bindingToPhone: false,
  bindToPhone: null,
  errorBindToPhone: null,
  unbinding: false,
  unbinded: false,
  unbindError: null,
};

const initOnboarding = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successOnboarding = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    user: action.data,
    currentStep: action.currentStep + 1,
  };
};

const failOnboarding = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const initGetCurrentOnboarding = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successGetCurrentOnboarding = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    user: action.data,
    currentStep: action.currentStep,
    completedOnboarding: action.completedOnboarding,
  };
};

const failGetCurrentOnboarding = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const initUpdateProfileOnboarding = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successUpdateProfileOnboarding = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    user: action.data,
    currentStep: action.currentStep,
    completedOnboarding: action.completedOnboarding,
  };
};

const failUpdateProfileOnboarding = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const initSendVerificationCode = (state, action) => {
  return {
    ...state,
    sendingVerificationCode: action.sendingVerificationCode,
  };
};

const successSendVerificationCode = (state, action) => {
  return {
    ...state,
    sendingVerificationCode: action.sendingVerificationCode,
    verificationCode: action.verificationCode,
  };
};

const failSendVerificationCode = (state, action) => {
  return {
    ...state,
    sendingVerificationCode: action.sendingVerificationCode,
    errorSendVerification: action.errorSendVerification,
  };
};

const initBindToPhone = (state, action) => {
  return {
    ...state,
    bindingToPhone: action.bindingToPhone,
  };
};

const successBindToPhone = (state, action) => {
  return {
    ...state,
    bindingToPhone: action.bindingToPhone,
    bindToPhone: action.bindToPhone,
  };
};

const failBindToPhone = (state, action) => {
  return {
    ...state,
    bindingToPhone: action.bindingToPhone,
    errorBindToPhone: action.errorBindToPhone,
  };
};

const initUnbindPhone = (state, action) => {
  return {
    ...state,
    unbinding: action.unbinding,
  };
};

const successUnbindPhone = (state, action) => {
  return {
    ...state,
    unbinding: action.unbinding,
    unbinded: action.unbinded,
  };
};

const failUnbindPhone = (state, action) => {
  return {
    ...state,
    unbinding: action.unbinding,
    unbindError: action.unbindError,
  };
};

const onboardingReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_ONBOARDING:
      return initOnboarding(state, action);
    case actionTypes.SUCCESS_ONBOARDING:
      return successOnboarding(state, action);
    case actionTypes.FAIL_ONBOARDING:
      return failOnboarding(state, action);
    case actionTypes.INIT_GET_CURRENT_ONBOARDING:
      return initGetCurrentOnboarding(state, action);
    case actionTypes.SUCCESS_GET_CURRENT_ONBOARDING:
      return successGetCurrentOnboarding(state, action);
    case actionTypes.FAIL_GET_CURRENT_ONBOARDING:
      return failGetCurrentOnboarding(state, action);
    case actionTypes.INIT_UPDATE_PROFILE_ONBOARDING:
      return initUpdateProfileOnboarding(state, action);
    case actionTypes.SUCCESS_UPDATE_PROFILE_ONBOARDING:
      return successUpdateProfileOnboarding(state, action);
    case actionTypes.FAIL_UPDATE_PROFILE_ONBOARDING:
      return failUpdateProfileOnboarding(state, action);
    case actionTypes.INIT_SEND_VERIFICATION_CODE:
      return initSendVerificationCode(state, action);
    case actionTypes.SUCCESS_SEND_VERIFICATION_CODE:
      return successSendVerificationCode(state, action);
    case actionTypes.FAIL_SEND_VERIFICATION_CODE:
      return failSendVerificationCode(state, action);
    case actionTypes.INIT_BIND_TO_PHONE:
      return initBindToPhone(state, action);
    case actionTypes.SUCCESS_BIND_TO_PHONE:
      return successBindToPhone(state, action);
    case actionTypes.FAIL_BIND_TO_PHONE:
      return failBindToPhone(state, action);
    case actionTypes.INIT_UNBIND_PHONE:
      return initUnbindPhone(state, action);
    case actionTypes.SUCCESS_UNBIND_PHONE:
      return successUnbindPhone(state, action);
    case actionTypes.FAIL_UNBIND_PHONE:
      return failUnbindPhone(state, action);
    default:
      return { ...state };
  }
};

export default onboardingReducer;
