import { v4 as uuidv4 } from "uuid";
import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading: false,
  data: [],
  cart_items: [],
  error: null,
  checkout_loading: false,
  checkout_data: null,
  checkout_error: null,
};

const initGetCart = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const succesGetCart = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    data: action.data,
    cart_items: action.cart_items,
  };
};

const failGetCart = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

//chekcout:

const initCheckout = (state, action) => {
  return {
    ...state,
    checkout_loading: action.checkout_loading,
    checkout_error: null,
  };
};

const successCheckout = (state, action) => {
  return {
    ...state,
    checkout_loading: false,
    checkout_data: action.checkout_data,
    checkout_error: null,
  };
};

const failCheckout = (state, action) => {
  return {
    ...state,
    checkout_loading: false,
    checkout_error: action.checkout_error,
  };
};

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_CART:
      return initGetCart(state, action);
    case actionTypes.SUCCESS_GET_CART:
      return succesGetCart(state, action);
    case actionTypes.FAIL_GET_CART:
      return failGetCart(state, action);
    //chekcout
    case actionTypes.INIT_CHECKOUT:
      return initCheckout(state, action);
    case actionTypes.SUCCESS_CHECKOUT:
      return successCheckout(state, action);
    case actionTypes.FAIL_CHECKOUT:
      return failCheckout(state, action);

    default:
      return { ...state };
  }
};

export default cartReducer;
