import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading: false,
  loadingChat: false,
  chats: [],
  chatMessages: [],
  error: null,
  errorLoadChat: null,
  sendingMessage: false,
  sendingError: null,
  messageSent: false,
  loadingSetAsRead: false,
  errorSetAsRead: null,
  successSetAsRead: false,
};

const initGetChats = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successGetChats = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    chats: action.chats,
  };
};

const failGetChats = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const initGetChat = (state, action) => {
  return {
    ...state,
    loadingChat: action.loadingChat,
  };
};

const successGetChat = (state, action) => {
  return {
    ...state,
    loadingChat: action.loadingChat,
    chatMessages: action.chatMessages,
  };
};

const failGetChat = (state, action) => {
  return {
    ...state,
    loadingChat: action.loadingChat,
    errorLoadChat: action.errorLoadChat,
  };
};

const initSendMessage = (state, action) => {
  return {
    ...state,
    sendingMessage: action.sendingMessage,
  };
};

const successSendMessage = (state, action) => {
  return {
    ...state,
    sendingMessage: action.sendingMessage,
    messageSent: action.messageSent,
  };
};

const failSendMessage = (state, action) => {
  return {
    ...state,
    sendingMessage: action.sendingMessage,
    sendingError: action.sendingError,
  };
};

const initSetAsRead = (state, action) => {
  return {
    ...state,
    loadingSetAsRead: action.loadingSetAsRead,
  };
};

const successSetAsRead = (state, action) => {
  return {
    ...state,
    loadingSetAsRead: action.loadingSetAsRead,
    successSetAsRead: action.successSetAsRead,
  };
};

const failSetAsRead = (state, action) => {
  return {
    ...state,
    loadingSetAsRead: action.loadingSetAsRead,
    errorSetAsRead: action.errorLoadChat,
  };
};

const chatReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_CHATS:
      return initGetChats(state, action);
    case actionTypes.SUCCESS_GET_CHATS:
      return successGetChats(state, action);
    case actionTypes.FAIL_GET_CHATS:
      return failGetChats(state, action);
    case actionTypes.INIT_GET_CHAT:
      return initGetChat(state, action);
    case actionTypes.SUCCESS_GET_CHAT:
      return successGetChat(state, action);
    case actionTypes.FAIL_GET_CHAT:
      return failGetChat(state, action);
    case actionTypes.INIT_POST_CHAT:
      return initSendMessage(state, action);
    case actionTypes.SUCCESS_POST_CHAT:
      return successSendMessage(state, action);
    case actionTypes.FAIL_POST_CHAT:
      return failSendMessage(state, action);
    case actionTypes.INIT_SET_READ_CHAT:
      return initSetAsRead(state, action);
    case actionTypes.SUCCESS_SET_READ_CHAT:
      return successSetAsRead(state, action);
    case actionTypes.FAIL_SET_READ_CHAT:
      return failSetAsRead(state, action);
    default:
      return { ...state };
  }
};

export default chatReducer;
