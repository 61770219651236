// Get Categories :
export const INIT_GET_CATEGORIES = "INIT_GET_CATEGORIES";
export const SUCCESS_GET_CATEGORIES = "SUCCESS_GET_CATEGORIES";
export const FAIL_GET_CATEGORIES = "FAIL_GET_CATEGORIES";

//####################################################
// ####################### CART ######################

//Get cart:

export const INIT_GET_CART = "INIT_GET_CART";
export const SUCCESS_GET_CART = "SUCCESS_GET_CART";
export const FAIL_GET_CART = "FAIL_GET_CART";
export const RESET_CART = "RESET_CART";

// post Add to Cart:

export const INIT_POST_ADD_CART = "INIT_POST_ADD_CART";
export const SUCCESS_POST_ADD_CART = "SUCCESS_POST_ADD_CART";
export const FAIL_POST_ADD_CART = "FAIL_POST_ADD_CART";

//checkout
export const INIT_CHECKOUT = "INIT_CHECKOUT";
export const SUCCESS_CHECKOUT = "SUCCESS_CHECKOUT";
export const FAIL_CHECKOUT = "FAIL_CHECKOUT";
export const RESET_CHECKOUT = "RESET_CHECKOUT";

//####################################################
// ####################### /CART ######################

// Auth :

export const INIT_AUTH = "INIT_AUTH";
export const SUCCESS_AUTH = "SUCCESS_AUTH";
export const FAIL_AUTH = "FAIL_AUTH";
export const LOGOUT_AUTH = "LOGOUT_AUTH";
export const REMEMBER_USER = "REMEMBER_USER ";
export const CLEAR_ERROR = "CLEAR_ERROR";

//####################################################
// ####################### Profile ######################

//get detail:
export const INIT_GET_DETAILS = "INIT_GET_DETAILS";
export const SUCCESS_GET_DETAILS = "SUCCESS_GET_DETAILS";
export const FAIL_GET_DETAILS = "FAIL_GET_DETAILS";

//get address:

export const INIT_GET_ADDRESS = "INIT_GET_ADDRESS";
export const SUCCESS_GET_ADDRESS = "SUCCESS_GET_ADDRESS";
export const FAIL_GET_ADDRESS = "FAIL_GET_ADDRESS";

//get Orders:

export const INIT_GET_ORDERS = "INIT_GET_ORDERS";
export const SUCCESS_GET_ORDERS = "SUCCESS_GET_ORDERS";
export const FAIL_GET_ORDERS = "FAIL_GET_ORDERS";

export const INIT_GET_ORDER_DETAIL = "INIT_GET_ORDER_DETAIL";
export const SUCCESS_GET_ORDER_DETAIL = "SUCCESS_GET_ORDER_DETAIL";
export const FAIL_GET_ORDER_DETAIL = "FAIL_GET_ORDER_DETAIL";

//####################################################
// ####################### /Profile ######################

//####################################################
// ####################### Products ######################

export const INIT_GET_PRODUCTS = "INIT_GET_PRODUCTS";
export const SUCCESS_GET_PRODUCTS = "SUCCESS_GET_PRODUCTS";
export const FAIL_GET_PRODUCTS = "FAIL_GET_PRODUCTS";
export const ADD_PRODUCTS_PARAM = "ADD_PRODUCTS_PARAM";
//single product:
export const INIT_GET_PRODUCT = "INIT_GET_PRODUCT";
export const SUCCESS_GET_PRODUCT = "SUCCESS_GET_PRODUCT";
export const FAIL_GET_PRODUCT = "FAIL_GET_PRODUCT";

//####################################################
// ####################### /Products ######################

//############ PRELOVED #############
export const INIT_GET_PRELOVED = "INIT_GET_PRELOVED";
export const SUCCESS_GET_PRELOVED = "SUCCESS_GET_PRELOVED";
export const FAIL_GET_PRELOVED = "FAIL_GET_PRELOVED";
//add preloved prduct
export const SUCCESS_ADD_PRELOVED = "SUCCESS_ADD_PRELOVED";
export const FAIL_ADD_PRELOVED = "FAIL_ADD_PRELOVED";
//profile preloved prodcut:
export const INIT_GET_PROFILE_PRELOVED = "INIT_GET_PROFILE_PRELOVED";
export const SUCCESS_GET_PROFILE_PRELOVED = "SUCCESS_GET_PROFILE_PRELOVED";
export const FAIL_GET_PROFILE_PRELOVED = "FAIL_GET_PROFILE_PRELOVED";
// preloved sales
export const SUCCESS_PRELOVED_SALES = "SUCCESS_PRELOVED_SALES";
export const FAIL_PRELOVED_SALES = "FAIL_PRELOVED_SALES";
// preloved buys
export const SUCCESS_PRELOVED_BUYS = "SUCCESS_PRELOVED_BUYS";
export const FAIL_PRELOVED_BUYS = "FAIL_PRELOVED_BUYS";

export const INIT_PRELOVED_CHECKOUT = "INIT_PRELOVED_CHECKOUT";
export const SUCCESS_PRELOVED_CHECKOUT = "SUCCESS_PRELOVED_CHECKOUT";
export const FAIL_PRELOVED_CHECKOUT = "FAIL_PRELOVED_CHECKOUT";
//############ END PRELOVED #############

//##############################################
//############ SHOP #############
export const INIT_GET_SHOP = "INIT_GET_SHOP";
export const SUCCESS_GET_SHOP = "SUCCESS_GET_SHOP";
export const FAIL_GET_SHOP = "FAIL_GET_SHOP";
export const INIT_GET_SHOP_PRODUCTS = "INIT_GET_SHOP_PRODUCTS";
export const SUCCESS_GET_SHOP_PRODUCTS = "SUCCESS_GET_SHOP_PRODUCTS";
export const FAIL_GET_SHOP_PRODUCTS = "FAIL_GET_SHOP_PRODUCTS";

//############ Wishlist #############
export const INIT_GET_WISHLIST = "INIT_GET_WISHLIST";
export const SUCCESS_GET_WISHLIST = "SUCCESS_GET_WISHLIST";
export const FAIL_GET_WISHLIST = "FAIL_GET_WISHLIST";

//CHATS
export const INIT_GET_CHATS = "INIT_GET_CHATS";
export const SUCCESS_GET_CHATS = "SUCCESS_GET_CHATS";
export const FAIL_GET_CHATS = "FAIL_GET_CHATS";

export const INIT_GET_CHAT = "INIT_GET_CHAT";
export const SUCCESS_GET_CHAT = "SUCCESS_GET_CHAT";
export const FAIL_GET_CHAT = "FAIL_GET_CHAT";

export const INIT_SET_READ_CHAT = "INIT_SET_READ_CHAT";
export const SUCCESS_SET_READ_CHAT = "SUCCESS_SET_READ_CHAT";
export const FAIL_SET_READ_CHAT = "FAIL_SET_READ_CHAT";

export const INIT_POST_CHAT = "INIT_POST_CHAT";
export const SUCCESS_POST_CHAT = "SUCCESS_POST_CHAT";
export const FAIL_POST_CHAT = "FAIL_POST_CHAT";

//STREAM CHATS
export const INIT_GET_STREAM_TOKEN = "INIT_GET_STREAM_TOKEN";
export const SUCCESS_GET_STREAM_TOKEN = "SUCCESS_GET_STREAM_TOKEN";
export const FAIL_GET_STREAM_TOKEN = "FAIL_GET_STREAM_TOKEN";

export const INIT_CONNECT_TO_STREAM = "INIT_CONNECT_TO_STREAM";
export const SUCCESS_CONNECT_TO_STREAM = "SUCCESS_CONNECT_TO_STREAM";
export const FAIL_CONNECT_TO_STREAM = "FAIL_CONNECT_TO_STREAM";

export const INIT_CHECK_USER_REGISTERED = "INIT_CHECK_USER_REGISTERED";
export const SUCCESS_CHECK_USER_REGISTERED = "SUCCESS_CHECK_USER_REGISTERED";
export const FAIL_CHECK_USER_REGISTERED = "FAIL_CHECK_USER_REGISTERED";

export const INIT_CREATE_CHAT = "INIT_CREATE_CHAT";
export const SUCCESS_CREATE_CHAT = "SUCCESS_CREATE_CHAT";
export const FAIL_CREATE_CHAT = "FAIL_CREATE_CHAT";

//FAQS
export const INIT_GET_FAQS = "INIT_GET_FAQS";
export const SUCCESS_GET_FAQS = "SUCCESS_GET_FAQS";
export const FAIL_GET_FAQS = "FAIL_GET_FAQS";

//TERM CONDITIONS
export const INIT_GET_TERM_CONDITIONS = "INIT_GET_TERM_CONDITIONS";
export const SUCCESS_GET_TERM_CONDITIONS = "SUCCESS_GET_TERM_CONDITIONS";
export const FAIL_GET_TERM_CONDITIONS = "FAIL_GET_TERM_CONDITIONS";

//PRIVACY POLICY
export const INIT_GET_PRIVACY_POLICIES = "INIT_GET_PRIVACY_POLICIES";
export const SUCCESS_GET_PRIVACY_POLICIES = "SUCCESS_GET_PRIVACY_POLICIES";
export const FAIL_GET_PRIVACY_POLICIES = "FAIL_GET_PRIVACY_POLICIES";

//ONBOARDING
export const INIT_ONBOARDING = "INIT_ONBOARDING";
export const SUCCESS_ONBOARDING = "SUCCESS_ONBOARDING";
export const FAIL_ONBOARDING = "FAIL_ONBOARDING";
export const INIT_GET_CURRENT_ONBOARDING = "INIT_GET_CURRENT_ONBOARDING";
export const SUCCESS_GET_CURRENT_ONBOARDING = "SUCCESS_GET_CURRENT_ONBOARDING";
export const FAIL_GET_CURRENT_ONBOARDING = "FAIL_GET_CURRENT_ONBOARDING";
export const INIT_UPDATE_PROFILE_ONBOARDING = "INIT_UPDATE_PROFILE_ONBOARDING";
export const SUCCESS_UPDATE_PROFILE_ONBOARDING = "SUCCESS_UPDATE_PROFILE_ONBOARDING";
export const FAIL_UPDATE_PROFILE_ONBOARDING = "FAIL_UPDATE_PROFILE_ONBOARDING";

//BIND TO PHONE
export const INIT_SEND_VERIFICATION_CODE = "INIT_SEND_VERIFICATION_CODE";
export const SUCCESS_SEND_VERIFICATION_CODE = "SUCCESS_SEND_VERIFICATION_CODE";
export const FAIL_SEND_VERIFICATION_CODE = "FAIL_SEND_VERIFICATION_CODE";

export const INIT_BIND_TO_PHONE = "INIT_BIND_TO_PHONE";
export const SUCCESS_BIND_TO_PHONE = "SUCCESS_BIND_TO_PHONE";
export const FAIL_BIND_TO_PHONE = "FAIL_BIND_TO_PHONE";

export const INIT_UNBIND_PHONE = "INIT_UNBIND_PHONE";
export const SUCCESS_UNBIND_PHONE = "SUCCESS_UNBIND_PHONE";
export const FAIL_UNBIND_PHONE = "FAIL_UNBIND_PHONE";
