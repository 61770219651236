import * as actionTypes from "../actions/actionTypes";

const initState = {
  sorting: "latest_to_oldest",
  search: "",
  page: "1",
  loading: false,
  productData: [],
  shopData: [],
  pagination: null,
  error: null,
  loading_product: false,
  product: null,
  error_product: null,
};

const initGetProducts = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successGetProducts = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    shopData: action.shopData,
    productData: action.productData,
    pagination: action.pagination,
  };
};

const failGetProducts = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const addParam = (state, action) => {
  return {
    ...state,
    [action.key]: action.value,
  };
};

const initGetProduct = (state, action) => {
  return {
    ...state,
    loading_product: action.loading_product,
  };
};

const successGetProduct = (state, action) => {
  return {
    ...state,
    loading_product: action.loading_product,
    product: action.product,
  };
};

const failGetProduct = (state, action) => {
  return {
    ...state,
    loading_product: action.loading_product,
    error_product: action.error_product,
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_PRODUCTS:
      return initGetProducts(state, action);
    case actionTypes.SUCCESS_GET_PRODUCTS:
      return successGetProducts(state, action);
    case actionTypes.FAIL_GET_PRODUCTS:
      return failGetProducts(state, action);
    case actionTypes.ADD_PRODUCTS_PARAM:
      return addParam(state, action);
    //single product:
    case actionTypes.INIT_GET_PRODUCT:
      return initGetProduct(state, action);
    case actionTypes.SUCCESS_GET_PRODUCT:
      return successGetProduct(state, action);
    case actionTypes.FAIL_GET_PRODUCT:
      return failGetProduct(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
