import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading: false,
  termConditions: [],
  error: null,
};

const initGetTermConditions = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successGetTermConditions = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    termConditions: action.termConditions,
  };
};

const failGetTermConditions = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const termConditionReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_TERM_CONDITIONS:
      return initGetTermConditions(state, action);
    case actionTypes.SUCCESS_GET_TERM_CONDITIONS:
      return successGetTermConditions(state, action);
    case actionTypes.FAIL_GET_TERM_CONDITIONS:
      return failGetTermConditions(state, action);
    default:
      return { ...state };
  }
};

export default termConditionReducer;
