import * as actionTypes from "../actions/actionTypes";
const initState = {
  user: null,
  userEmail: null,
  loadingAuth: false,
  error: null,
  currentUser: null,
  currentUserError: null,
  loggedOut: false,
  rememberUser: null,
};

const initAuth = (state, action) => {
  return {
    ...state,
    loadingAuth: action.loadingAuth,
  };
};

const successAuth = (state, action) => {
  return {
    ...state,
    user: action.user,
    loadingAuth: action.loadingAuth,
    userEmail: action.userEmail,
    loggedOut: action.loggedOut,
  };
};

const failAuth = (state, action) => {
  return {
    ...state,
    error: action.error,
    loadingAuth: action.loadingAuth,
  };
};

const successLogoutAuth = (state, action) => {
  return {
    ...state,
    user: action.user,
    loggedOut: action.loggedOut,
    userEmail: action.userEmail,
    rememberUser: action.rememberUser,
  };
};

const clearError = (state, action) => {
  return {
    ...state,
    error: action.error,
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_AUTH:
      return initAuth(state, action);
    case actionTypes.SUCCESS_AUTH:
      return successAuth(state, action);
    case actionTypes.FAIL_AUTH:
      return failAuth(state, action);
    case actionTypes.REMEMBER_USER:
      return { ...state, rememberUser: action.rememberUser };
    case actionTypes.LOGOUT_AUTH:
      return successLogoutAuth(state, action);
    case actionTypes.CLEAR_ERROR:
      return clearError(state, action);
    default:
      return state;
  }
};

export default Reducer;
