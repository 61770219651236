import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import categoryReducer from "./categoryReducer";
import authReducer from "./authReducer";
import profileReducer from "./profile/detailReducer";
import productsReducer from "./productsReducer";
import prelovedReducer from "./prelovedReducer";
import shopReducer from "./shopReducer";
import chatReducer from "./chatReducer";
import faqReducer from "./faqReducer";
import termConditionReducer from "./termConditionReducer";
import privacyPolicyReducer from "./privacyPolicyReducer";
import onboardingReducer from "./onBoardingReducer";
import streamChatReducer from "./streamChatReducer";

const rootReducer = {
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  categoryData: categoryReducer,
  authData: authReducer,
  profile: profileReducer,
  products: productsReducer,
  preloved: prelovedReducer,
  shop: shopReducer,
  chatsData: chatReducer,
  faqData: faqReducer,
  termConditionData: termConditionReducer,
  privacyPolicyData: privacyPolicyReducer,
  onboardingData: onboardingReducer,
  streamChatData: streamChatReducer,
};

export default rootReducer;
