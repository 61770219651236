import * as actionTypes from "../actions/actionTypes";

const initState = {
  loading: false,
  streamToken: null,
  chatClient: null,
  chatChannel: null,
  userRegistered: null,
  error: null,
};

const initGetStreamToken = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successGetStreamToken = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    streamToken: action.streamToken,
  };
};

const failGetStreamToken = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const initConnectToStream = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successConnectToStream = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    chatClient: action.chatClient,
  };
};

const failConnectToStream = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const initCheckUserRegistered = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successCheckUserRegistered = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    userRegistered: action.userRegistered,
  };
};

const failCheckUserRegistered = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const initCreateChat = (state, action) => {
  return {
    ...state,
    loading: action.loading,
  };
};

const successCreateChat = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    chatChannel: action.chatChannel,
  };
};

const failCreateChat = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error,
  };
};

const streamChatReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_STREAM_TOKEN:
      return initGetStreamToken(state, action);
    case actionTypes.SUCCESS_GET_STREAM_TOKEN:
      return successGetStreamToken(state, action);
    case actionTypes.FAIL_GET_STREAM_TOKEN:
      return failGetStreamToken(state, action);
    case actionTypes.INIT_CONNECT_TO_STREAM:
      return initConnectToStream(state, action);
    case actionTypes.SUCCESS_CONNECT_TO_STREAM:
      return successConnectToStream(state, action);
    case actionTypes.FAIL_CONNECT_TO_STREAM:
      return failConnectToStream(state, action);
    case actionTypes.INIT_CHECK_USER_REGISTERED:
      return initCheckUserRegistered(state, action);
    case actionTypes.SUCCESS_CHECK_USER_REGISTERED:
      return successCheckUserRegistered(state, action);
    case actionTypes.FAIL_CHECK_USER_REGISTERED:
      return failCheckUserRegistered(state, action);
    case actionTypes.INIT_CREATE_CHAT:
      return initCreateChat(state, action);
    case actionTypes.SUCCESS_CREATE_CHAT:
      return successCreateChat(state, action);
    case actionTypes.FAIL_CREATE_CHAT:
      return failCreateChat(state, action);
    default:
      return { ...state };
  }
};

export default streamChatReducer;
